import {
  companyName,
  privacyPolicyLink,
  clientAgreementLink,
  companyWebsite,
} from "./content";

export const REGISTER = `By clicking here I give my consent for ${companyName} to contact me for marketing purposes. You can opt out at any time. For further details please see our Marketing and Communication Policy Statement.`;

export const CLIENT_AGREEMENT = "I have read, understood, and agreed to Invest Matrix's client agreement which includes order execution policy, conflict of interest policy, privacy policy, third party disclosure policy and any other terms in the client agreement.";

export const IB_AGREEMENT = `You have read, understood, acknowledged, and agreed to all ${companyName}'s policies, terms & conditions and client agreements which are available on the company's <a href=${companyWebsite} target='_blank'>website</a>`;

export const COUNTRY_REGULATIONS = "I confirm that I do not breach any regulation of my country of residence in trading with Invest Matrix.";

export const E_SIGNATURE = "My electronic signature should be considered as a legal and official signature.";
