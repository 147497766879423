import {
  useState, useEffect, useRef
} from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  AvForm, AvField, AvGroup, AvFeedback
} from "availity-reactstrap-validation";
import {
  Container, Col, Row,
  Label, Button, Badge,
} from "reactstrap";
import MetaTags from "react-meta-tags";
import { withTranslation } from "react-i18next";
import Icofont from "react-icofont";
import { getAccountsStart, updatePassword } from "../../../store/actions";

import CardWrapper from "components/Common/CardWrapper";
import PageHeader from "components/Forex/Common/PageHeader";
import Loader from "components/Common/Loader";
import Widgets from "components/Common/Widgets";

const Transfers = (props) => {
  const dispatch = useDispatch();
  const formRef = useRef();
  const { accounts, submitting, loading } = useSelector((state) => state.forex.accounts);
  const [account, setAccount] = useState(accounts && accounts[0]);

  useEffect(() => {
    dispatch(getAccountsStart());
  }, []);

  
  const [showPassword, setShowPassword] = useState(false);
  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const confirmPassword = (value, ctx, input, cb) => {
    if (value !== ctx.password) cb("Password doesn't match!");
    else cb(true);
  };

  const handleValidSubmit = (event, values) => {
    dispatch(updatePassword({
      _id: values.account,
      body: {
        password: values.password,
        type: values.type,
      }
    }));
    formRef.current.reset();
  };
  
  const platforms = ["VERTEXFX"];
  const [activeTab, setActiveTab] = useState(platforms[0]);

  useEffect(() => {
    if (accounts?.length > 0 && !submitting) {
      const firstAccount = accounts?.filter(acc => acc.platform === platforms[0])[0];
      setAccount(firstAccount && firstAccount);
    }
  }, [accounts, activeTab, submitting]);

  console.log("account", account, activeTab, platforms[0]);

  return (
    <>
      <MetaTags>
        <title>{props.t("Change Password")}</title>
      </MetaTags>
      <Container>
        <div className="page-content mt-5">
          <PageHeader title="Change Password"></PageHeader>
          <CardWrapper className="mt-4 p-4 glass-card">
            <Row className="mb-4">
              <Col className="d-flex justify-content-between">
                <h3 className="">{props.t("Change Password")}</h3>
              </Col>
            </Row>
            <Row>
              {
                loading ? <Loader /> : <>
                  <AvForm
                    ref={formRef}
                    onValidSubmit={(e, v) => {
                      handleValidSubmit(e, v);
                    }}  
                  >
                    <Row>
                      <div className="d-flex justify-content-center">
                        <Widgets
                          tabs={platforms}
                          setTab={setActiveTab}
                        >
                        </Widgets>
                      </div>
                      <Col md={activeTab === "VERTEXFX" ? "12" : "6"}>
                        <AvField type="select"
                          name="account" value={account && account?._id} label={props.t("Account No.*")} className="mt-1 mb-2 form-select" required
                          onChange={(e) => {
                            setAccount(accounts?.find(acc => acc._id === e.target.value));
                          }}
                          validate={{
                            required: {
                              value: true,
                              errorMessage: "Account is required"
                            }
                          }}
                        >
                          {accounts?.filter(acc => acc.platform === platforms[0])?.map((account) =>
                            <option key={account.login} value={account._id}>{account.login} ({account.type} {account.platform})</option>
                          )};
                        </AvField>
                      </Col>
                      {
                        activeTab !== "VERTEXFX" && <>
                          <Col md="6" className="mt-3 mt-md-0">
                            <AvField type="select" name="type" value={"main"} label={props.t("Type*")} className="mt-1 mb-2 form-select" required>
                              <option value={props.t("main")}>{props.t("Master")}</option>
                              <option value={props.t("investor")}>{props.t("Investor")}</option>
                            </AvField>
                          </Col>
                        </>
                      }

                      <Col md="6" className="mt-3">
                        <AvGroup>
                          <Label for="password" className="mb-1 d-flex align-items-center">{props.t("New Password")}
                            <Icofont icon={showPassword ? "eye-blocked" : "eye"} className="show-password"
                              onClick={handleShowPassword}
                            ></Icofont>
                          </Label>
                          <AvField name="password" type={showPassword ? "text" : "password"} id="password"
                            validate={{
                              required: {
                                value: true,
                                errorMessage: "Password is required"
                              },
                              pattern: {
                                value: /^(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]/,
                                errorMessage: "Password must contain at least one uppercase letter and one number"
                              },
                              minLength: {
                                value: 7,
                                errorMessage: "Password must be more than 6 characters"
                              },
                            }} />
                          
                        </AvGroup>
                      </Col>
                      <Col md="6" className="mt-3">
                        <AvGroup>
                          <Label for="confirm_password" className="mb-1">{props.t("Confirm Password")}</Label>
                          <AvField name="confirm_password" type="password" id="confirm_password"
                            validate={{
                              required: {
                                value: true,
                                errorMessage: "Password is required"
                              },
                              custom: confirmPassword
                            }}
                          />
                          <AvFeedback>{props.t("Password doesn't match")}</AvFeedback>
                        </AvGroup>
                      </Col>
                    </Row>
                    {
                      activeTab == 2 && <Col sm="12" className="mt-3">
                        <h6>{props.t("Changing the password for this account will change the password for the following accounts")}</h6>
                        <div className="d-flex flex-columns flex-wrap">
                          {accounts?.filter(
                            acc => (acc.platform === platforms[activeTab]
                              && acc.leverage === account?.leverage
                            ))?.map((account) =>
                            <Badge className="p-2 color-bg-btn mx-1 my-2 fs-6" key={account.login}>{account.login} ({account.type} {account.platform})</Badge>
                          )}
                        </div>
                      </Col>
                    }
                    <div className="text-center mt-4">
                      <Button type="submit" disabled={submitting} className="color-bg-btn border-0 px-4">{props.t("Change Password")}</Button>
                    </div>
                  </AvForm>
                </>
              }
            </Row>
          </CardWrapper>
        </div>
      </Container>
    </>
  );
};

export default withTranslation()(Transfers);