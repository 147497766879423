import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { AvField, AvForm } from "availity-reactstrap-validation";
import CardWrapper from "components/Common/CardWrapper";
import { useTranslation } from "react-i18next";
import {
  Button, Col, Row, Table
} from "reactstrap";
import AvFieldSelect from "components/Common/AvFieldSelect";
import moment from "moment";
import { fetchStatement } from "store/actions";
import Loader from "components/Common/Loader";
import CustomPagination from "components/Common/CustomPagination";
import { MetaTags } from "react-meta-tags";
import { Thead } from "react-super-responsive-table";

const platformOptions = [{
  label: "VERTEXFX",
  value: "VERTEXFX",
},
// {
//   label: "MT5",
//   value: "MT5",
// }, {
//   label: "MT4",
//   value: "MT4",
// }, 
];


const Filteration = ({ 
  onLoginSelect,
  platform,
  setPlatform,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { statement, loading } = useSelector(state => state.forex.ib.agreements);

  const [sizePerPage, setSizePerPage] = useState(10);
  const [dateFrom, setDateFrom] = useState(String(moment().subtract(30, "days").format("YYYY-MM-DD")));
  const [dateTo, setDateTo] = useState(String(moment().add(1, "day").format("YYYY-MM-DD")));
  const [columns, setColumns] = useState([]);

  const handleDateFrom = (e) => setDateFrom(e.target.value);
  const handleDateTo = (e) => setDateTo(e.target.value);

  const loadStatement = (page = 1, limit = 10, platform) => {
    dispatch(fetchStatement({
      limit,
      page,
      platform: platform,
      dateFrom: dateFrom,
      dateTo: dateTo,
    }));
  };

  useEffect(() => {
    if (platform) {
      loadStatement(1, sizePerPage, platform);
    }
  }, [sizePerPage, platform]);

  useEffect(() => {
    setColumns(getColumns());
  }, [platform]);

  const baseColumns = [
    {
      dataField: "clientLogin",
      text: t("Client Login"),
      formatter: (obj) => {
        return (
          <div onClick={() => onLoginSelect(statement.clientLogin)}>
            <strong className="text-capitalize">{obj.clientLogin}</strong>
          </div>
        );
      }
    },
  ];

  const getColumns = () => {
    switch (platform) {
      case "MT5":
      case "MT4":
        return [
          ...baseColumns,
          {
            dataField: "lotsOpened",
            text: t("Lots Opened"),
            formatter: (val) => (val ? parseFloat(val.lotsOpened) / 10000 : "-")
          },
          {
            dataField: "commission",
            text: t("Commission"),
          },
          {
            dataField: "lotsClosed",
            text: t("Lots Closed"),
            formatter: (val) => !val?.lotsClosed ? "-" : (parseFloat(val.lotsClosed) / 10000)
          },
          {
            dataField: "rebate",
            text: t("Rebate"),
          },
        ];
      case "VERTEXFX":
        return [
          ...baseColumns,
          {
            dataField: "lotsClosed",
            text: t("Lots Closed"),
          },
          {
            dataField: "rebate",
            text: t("Rebate"),
          },
        ];
      default:
        return [];
    }
  };

  return (
    <CardWrapper className="mt-3 px-5 py-4 pb-2 glass-card shadow">
      <MetaTags>
        <title>{t("Statement")}</title>
      </MetaTags>
      <AvForm onValidSubmit={() => loadStatement(1, sizePerPage, platform)}>
        <Row className="mt-3 justify-content-between align-items-end">
          <Col xs="12" md="3" lg="3">
            <AvFieldSelect
              className="mt-1 form-select"
              name="platform"
              label={t("Platform")}
              errorMessage={t("Platform is required")}
              validate={{ required: { value: true } }}
              onChange={(e) => setPlatform(e)}
              value={platform}
              options={platformOptions.map((obj) => {
                return ({
                  label: obj.label,
                  value: obj.value,
                });
              })}
            />
          </Col>
          <Col xs="12" md="3" lg="3">
            <AvField
              className="mt-1 mb-2"
              type="date"
              name="dateFrom"
              label={t("From Date")}
              value={dateFrom}
              validate={{ date: { format: "YYYY-MM-DD" } }}
              onChange={handleDateFrom}
            />
          </Col>
          <Col xs="12" md="3" lg="3">
            <AvField
              className="mt-1 mb-2"
              type="date"
              name="dateTo"
              label={t("To Date")}
              validate={{ date: { format: "YYYY-MM-DD" } }}
              value={dateTo}
              onChange={handleDateTo}
            />
          </Col>
          <Col>
            <Button
              className="btn-light color-bg-btn border-0 shadow mb-md-2 mt-3 mt-lg-0 w-100 text-white text-uppercase"
              loading={true}
            >
              {t("search")}
            </Button>
          </Col>
        </Row>
      </AvForm>
      <hr className="my-4" />
      <div className="mt-4 border rounded-3">
        <Table borderless responsive hover className="text-center mb-0">
          <Thead className="table-light">
            <tr>
              {columns.map((column, index) =>
                <th key={index}>{column?.text}</th>
              )}
            </tr>
          </Thead>
          <tbody>
            {loading ?
              <tr>
                <td colSpan="5" className="text-center"><Loader /></td>
              </tr>
              : statement?.docs?.map((statement, index) =>
                <tr key={index} className="border-top"
                  onClick={() => onLoginSelect(statement?.clientLogin)}>
                  {columns.map((column, index) =>
                    <td key={index}>
                      {column?.formatter ? column.formatter(statement) : statement[column?.dataField]}
                    </td>
                  )}
                </tr>
              )
            }
          </tbody>
        </Table>
      </div>
      <div className="mt-4">
        <CustomPagination
          {...statement}
          setSizePerPage={setSizePerPage}
          sizePerPage={sizePerPage}
          onChange={loadStatement}
        />
      </div>
    </CardWrapper>
  );
};

export default Filteration;